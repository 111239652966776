import { ParsedUrlQuery } from "querystring";
import { EventProjectVersionSlug } from "../data/common/common.models";
import {
    IS_MEETUP_DOMAIN_PARAM,
    IS_MEETUP_QUERY_PARAM,
    IS_PARTNERS_DOMAIN_PARAM,
    IS_PARTNERS_QUERY_PARAM
} from "./routes";
import { DraftScope } from "../data/srm/models/draft.models";

export const getEventVersionInfoFromUrl = (params: ParsedUrlQuery): EventProjectVersionSlug | undefined => {
    const fullEventName = params.event as string;

    if (!fullEventName) {
        return undefined;
    }

    const splitEventName = fullEventName.split('-');

    if (!splitEventName[2]) {
        return {
            projectKeyword: splitEventName[0]?.toLowerCase(),
            version: splitEventName[1]?.toLowerCase(),
            eventSlug: fullEventName.toLowerCase()
        };
    }

    return {
        projectKeyword: splitEventName[0]?.toLowerCase(),
        version: `${splitEventName[1] ?? ''} ${splitEventName[2] ?? ''} ${splitEventName[3] ?? ''}`.toLowerCase(),
        eventSlug: fullEventName.toLowerCase()
    };
}

export const getStageNumberFromHash = (hash?: string, max: number = 3): (number | undefined) => {
    if (hash === undefined) {
        return undefined;
    }

    if (hash === "stepFinal") {
        return 100;
    }

    const slug = +(hash[hash.length - 1]);
    if (isNaN(slug) || slug === 0) {
        return 0;
    }

    if (slug > max) {
        return 2;
    }

    return slug - 1;
}

export const getScopeFromDomain = (url: string, isHost: boolean): DraftScope => {
    let search = "";
    let host;

    if (isHost) {
        host = url;
    } else {
        const parsedUrl = new URL(url);
        search = parsedUrl.search;
        host = parsedUrl.host;
    }

    if (search.includes(IS_PARTNERS_QUERY_PARAM)) {
        return "PARTNER";
    }

    if (host.includes(IS_PARTNERS_DOMAIN_PARAM)) {
        return "PARTNER";
    }

    if (search.includes(IS_MEETUP_QUERY_PARAM)) {
        return "MEETUP";
    }

    if (host.includes(IS_MEETUP_DOMAIN_PARAM)) {
        return "MEETUP";
    }

    // for testing
    // if (isLocalEnvironment) {
    //     return "MEETUP";
    // }

    return "REGULAR";
}
