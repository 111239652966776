import { Draft, DraftActorsSpeaker, DraftScope } from "./models/draft.models";
import { StepFirstModel, StepSecondModel, StepThirdModel } from "./models/forms.models";
import { removeUndefined } from "../../utils/common";
import { Lang } from "../common/common.models";

export const getInitDraft = (lang: Lang, scope?: DraftScope): Draft => ({
    stage: 0,
    lang,
    scope: scope ?? "REGULAR"
});

// const updatePrefixFields = (data: StepFirstModel): StepFirstModel => {
//     if (data.twitter) {
//         data.twitter = `@${data.twitter.replaceAll("@", "")}`;
//     }
//     if (data.phone) {
//         data.phone = `+${data.phone.replaceAll("+", "")}`;
//     }
//
//     return data;
// }

export const mergeDraftWithStepFirst = (draft: Draft, data: StepFirstModel): Draft => {
    const speaker: DraftActorsSpeaker = {
        ...draft?.actor ?? {},
        ...removeUndefined(data)
    };

    return {
        ...draft,
        actor: {
            ...draft?.actor ?? {},
            ...speaker
        },
        stage: 1
    };
}

export const mergeDraftWithStepSecond = (draft: Draft, data: StepSecondModel): Draft => {
    return {
        ...draft,
        activity: {
            ...draft?.activity ?? {},
            ...removeUndefined(data),
            coSpeakerCandidates: undefined
        },
        coSpeakerCandidates: data.coSpeakerCandidates,
        stage: 2
    }
}

export const mergeDraftWithStepThird = (draft: Draft, data: StepThirdModel): Draft => {
    return {
        ...draft,
        activity: {
            ...draft?.activity ?? {},
            ...removeUndefined(data),
        },
        stage: 100
    }
}

export const removeUnEditableFields = (draft: Draft): Draft =>
    removeUndefined({
        ...draft,
        actions: undefined,
        createdAt: undefined,
        createdBy: undefined,
        eventId: undefined,
        sessionId: undefined,
        sub: undefined,
        updatedAt: undefined,
        updatedBy: undefined,
        workspaceId: undefined,
    })

export const draftComparerDesc = (a: Draft, b: Draft) => b.id - a.id;
