import { Draft, DraftScope } from "./models/draft.models";
import {
    StepFirstModel,
    stepFirstModelDefaultValues,
    StepSecondModel,
    stepSecondModelDefaultValues,
    StepThirdModel,
    stepThirdModelDefaultValues
} from "./models/forms.models";
import { AuthStore } from "../auth/AuthStore";
import { removeUndefined } from "../../utils/common";

export const extractStepFirstModelFromDraft = (draft?: Draft): StepFirstModel => {
    if (!draft || !draft.actor) {
        return stepFirstModelDefaultValues;
    }

    return {
        ...stepFirstModelDefaultValues,
        ...draft.actor,
        acceptGDPR: false
    }
}

export const extractStepSecondModelFromDraft = (draft?: Draft): StepSecondModel => {
    if (!draft || !draft.activity) {
        return stepSecondModelDefaultValues;
    }

    return {
        ...stepSecondModelDefaultValues,
        coSpeakerCandidates: draft?.coSpeakerCandidates ?? undefined,
        ...draft.activity,
    }
}

export const checkIfOnlyAutoFilledEdited = (data: StepFirstModel, authStore: AuthStore): boolean => {
    let obj = { ...data };
    const { nameAndSurname, userEmail } = authStore;

    const removeFields = [ "acceptGDPR" ];

    if (obj.firstName && obj.firstName === nameAndSurname?.firstName) {
        obj.firstName = undefined;
    }

    if (obj.lastName && obj.lastName === nameAndSurname?.lastName) {
        obj.lastName = undefined;
    }

    if (obj.email && obj.email === userEmail) {
        obj.email = undefined;
    }

    obj = removeUndefined(obj);
    removeFields.forEach(f => {
        if (obj[f] !== undefined) {
            delete obj[f];
        }
    });

    return Object.keys(obj).length === 0;
}

export const checkDontEdited = (data, init): boolean => {
    let obj = { ...data };

    for (const k in obj) {
        if (obj[k] && init[k] && obj[k] === init[k]) {
            obj[k] = undefined;
        }
    }

    obj = removeUndefined(obj);
    return Object.keys(obj).length === 0;
}

export const extractStepThirdModelFromDraft = (draft?: Draft): StepThirdModel => {
    if (!draft || !draft.activity) {
        return stepThirdModelDefaultValues;
    }

    return {
        ...stepThirdModelDefaultValues,
        ...draft.activity,
    }
}

export const stageValidate = (stage: number) => {
    if (stage < 0) {
        return false;
    }
    return !(stage > 2 && stage !== 100);
};

export const replaceEventNameInText = (text: string, name: string) => text.replace("NAME", name);

export const replaceEmailInText = (text: string, email: string, scope: DraftScope) =>
    text
        .replace("MAIL", email)
        .replace("OUR_MAIL",
            scope === "PARTNER" ? "partners@jugru.org" :
                scope === "MEETUP" ? "yana.liubavskaia@jugru.org" :
                    "program@jugru.org"
        );
