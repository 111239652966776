import { CfpEvent } from "../data/srm/models/events.models";

export type ApplicationRoute = typeof APPLICATION_ROUTES;

export const IS_PARTNERS_QUERY_PARAM = "isPartner";
export const IS_PARTNERS_DOMAIN_PARAM = ".partner.";
export const IS_MEETUP_DOMAIN_PARAM = ".meetup.";
export const IS_MEETUP_QUERY_PARAM = "isMeetup";

export const APPLICATION_ROUTES = {
    main: {
        route: '/',
        createRoute: (path?: string) => `/${path === undefined ? '' : path}`,
        createRouteForCfp: (event: CfpEvent, isPartnerFlag: boolean) => {
            const eventVersion = event.eventSlug ?? `${event.projectKeyword.toLowerCase()}-${event.version.toLowerCase()}`;
            return `/${event.workspaceSlug ?? event.workspaceId}/${eventVersion}${isPartnerFlag ? `/?${IS_PARTNERS_QUERY_PARAM}` : ""}`
        },
        params: {},
    },
}
