import SrmStore from "./srm.store";
import { SrmApiService } from "./srm.api.service";
import { Draft, DraftScope } from "./models/draft.models";
import { removeUndefined } from "../../utils/common";
import { extractStepFirstModelFromDraft } from "./forms.actions";
import { Lang } from "../common/common.models";

class SrmService {
    constructor(private srmStore: SrmStore, private srmApiService: SrmApiService) {
    }

    reset(needToCleanFinalData: boolean = true) {
        this.srmStore.reset(needToCleanFinalData);
    }

    // load last draft and return stage
    loadLastDraft = (eventId: number): Promise<number> => this.srmApiService.getDrafts(eventId)
        .then(response => {
            this.srmStore.setDrafts(response);
            return response;
        })
        .then(response => {
            if (response.length > 0) {
                // use first draft! TODO change drafts
                const draft = response[0];

                // if stage not set it to 0
                draft.stage = !draft.stage ? 0
                    : draft.stage === 100 ? 0
                        : draft.stage;

                this.srmStore.setCurrentDraft(draft);

                return draft.stage;
            } else {
                this.srmStore.setCurrentDraft(null);
                return 0;
            }
        });

    // post new draft and put new data to it
    postNewDraft = (eventId: number, draft: Draft, lang, scope: DraftScope) => {
        return this.srmApiService.postNewDraft(eventId, lang, scope)
            .then(response => {
                this.srmStore.setCurrentDraft(response);
                this.srmStore.updateDraft(response);
                return response;
            })
            .then(response => this.srmApiService.patchDraft({ ...response, ...draft, lang }))
    };

    patchEditedDraft = (eventId: number, draft: Draft, lang: Lang, scope: DraftScope) => {
        let action: () => Promise<Draft>;
        // if current draft is now exist
        if (this.srmStore.currentDraftIsEmpty) {
            action = () => this.postNewDraft(eventId, draft, lang, scope);
        } else {
            action = () => this.srmApiService
                .patchDraft({
                    ...this.srmStore.currentDraft,
                    ...draft,
                    lang,
                    // add scope flag
                    activity: {
                        ...draft?.activity,
                        scope
                    }
                })
                .catch(result => {
                    // when response is 404 try to post new draft
                    if (result.response.status === 404) {
                        return this.postNewDraft(eventId, removeUndefined({
                            ...this.srmStore.currentDraft, ...draft,
                            id: undefined
                        }), lang, scope)
                    }
                    throw new Error(result.statusText);
                });
        }

        return action()
            .then(response => {
                this.srmStore.setCurrentDraft(response);
                this.srmStore.updateDraft(response);
                return true;
            })
    };

    postFinalDraft = (eventId: number, draft: Draft, lang: Lang, scope: DraftScope) => this.srmApiService.patchDraft({
        ...draft,
        lang
    })
        .catch(result => {
            // when response is 404 try to post new draft
            if (result.response.status === 404) {
                return this.postNewDraft(eventId, removeUndefined({
                    ...this.srmStore.currentDraft, ...draft,
                    id: undefined
                }), lang, scope)
            }
            throw new Error(result.statusText);
        })
        .then(response => this.srmApiService.submitDraft(response))
        .then(response => {
            this.srmStore.setCurrentDraft(null);
            this.srmStore.deleteDraft(response);
            this.srmStore.setFinalScreenData(extractStepFirstModelFromDraft(response));
            return true;
        });

    cancelDraft = () => {
        if (this.srmStore.currentDraftIsEmpty) {
            return Promise.resolve(true);
        }

        if (!this.srmStore.currentDraft?.id) {
            throw new Error("missing id when cancel draft");
        }

        return this.srmApiService.cancelDraft(this.srmStore.currentDraft.id)
            .catch(result => {
                // when response is 404 - simple reset
                if (result.response.status === 404) {
                    return true;
                }
                throw new Error(result.statusText);
            })
            .then(() => {
                return true;
            })
            .finally(() => {
                this.srmStore.reset();
            });
    };
}

export default SrmService;
