import { getBackendHostUrl, getEnvironment, getHostUrl, useMocks } from "../../../environment";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { CfpEvent, OpenCfpListApiResponse } from "./models/events.models";
import { EventProjectVersionSlug, Lang } from "../common/common.models";
import { Draft, DraftApiResponse, DraftsApiResponse, DraftScope } from "./models/draft.models";
import { AuthStore } from "../auth/AuthStore";
import { draftComparerDesc, getInitDraft, removeUnEditableFields } from "./draft.actions";
import { reaction, when } from "mobx";
import { AuthService } from "../auth/auth.service";

const environment = getEnvironment();

// https://gitlab.jugru.team/program-department/speaker-personal-area/srm-docs/-/blob/main/docs/architecture/cfp/index.md

const baseApiPath = 'api/v2/public';
const openCfpApiPath = (workspaceOrSlugId: string) => `/workspaces/${workspaceOrSlugId}/cfp/open`;
const draftsWithEventId = (eventId: number) => `/events/${eventId}/cfp/drafts`;
const draftApiPath = (id: number) => `/cfp/drafts/${id}`;
const draftApiPathSubmit = (id: number) => `${draftApiPath(id)}:submit`;
const draftApiPathCancel = (id: number) => `${draftApiPath(id)}:cancel`;

const getBaseUrl = (serverSide: boolean = false) => {
    if (useMocks) {
        return environment === 'local' ? 'http://localhost:3000/mocks/api/v1'
            : environment === 'development' ? 'https://callforpapers-dev.jugru.org/mocks/api/v1'
                : 'https://callforpapers-dev.jugru.org/mocks/api/v1'
    }

    if (serverSide) {
        return `${getBackendHostUrl()}${baseApiPath}`;
    }

    return `${getHostUrl()}${baseApiPath}`;
}


export class SrmApiService {
    public openCfpListApiResponse?: OpenCfpListApiResponse;
    private api: AxiosInstance;

    constructor(
        private serverSide: boolean = false,
        private authStore?: AuthStore,
        private authService?: AuthService
    ) {
        this.initAxios();

        if (!serverSide) {
            reaction(
                () => authStore?.accessToken,
                () => {
                    // info("[SrmApiService] accessTokenRaw changed");
                    this.initAxios();
                }
            )

            when(() => authStore?.isLogged,
                () => {
                    // info("[SrmApiService] is logged ==> recreate axios api");
                    this.initAxios();
                });

            when(() => !authStore?.isLogged,
                () => {
                    // info("[SrmApiService] is not logged ==> recreate axios api");
                    this.initAxios();
                });
        }
    }

    private async verifyToken(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
        if (this.authStore?.isLogged) {
            return this.authService?.checkTokenAndRenew()
                .then(() => config)
                .catch(() => config);
        }

        return Promise.resolve(config);
    }

    private initAxios(isAnonymous?: boolean) {
        let settings: AxiosRequestConfig = { baseURL: getBaseUrl(this.serverSide) };

        if (this.authStore?.isLogged && this.authStore?.accessTokenRaw && !isAnonymous) {
            settings = {
                ...settings,
                headers: { authorization: `Bearer ${this.authStore.accessTokenRaw}` }
            };
        }

        this.api = axios.create(settings);

        // add interceptor
        this.api.interceptors.request.use(this.verifyToken.bind(this));
    }

    // GET /api/v2/public/workspaces/wdo-sbzi-ccg/cfp/open
    async getOpenCfp(workspaceOrSlugId: string): Promise<OpenCfpListApiResponse> {
        return this.openCfpListApiResponse ??
            this.api.get(openCfpApiPath(workspaceOrSlugId))
                .then((result: AxiosResponse<OpenCfpListApiResponse>) => {
                    if (!this.serverSide) {
                        this.openCfpListApiResponse = result.data;
                    }
                    return result.data;
                })
    }

    // GET /api/v2/public/events/100061/cfp/drafts
    async getDrafts(eventId: number): Promise<Draft[]> {
        return this.api.get(draftsWithEventId(eventId))
            .then((result: AxiosResponse<DraftsApiResponse>) => result.data?.data ?? [] as Draft[])
            .then(drafts => drafts.sort(draftComparerDesc));
    }

    // POST /api/v2/public/events/100061/cfp/drafts
    async postNewDraft(eventId: number, lang: Lang, scope: DraftScope): Promise<Draft> {
        return this.api.post(draftsWithEventId(eventId), getInitDraft(lang, scope))
            // @ts-ignore
            .then((result: AxiosResponse<DraftApiResponse>) => result.data?.data as Draft);
    }

    // GET /api/v2/public/cfp/drafts/100001000
    async getDraft(id: number): Promise<Draft | undefined> {
        return this.api.get(draftApiPath(id))
            .then((result: AxiosResponse<DraftApiResponse>) => result.data.data as Draft);
    }

    // PATCH /api/v2/public/cfp/drafts/100001000
    async patchDraft(draft: Draft): Promise<Draft> {
        if (!draft.id) {
            throw new Error("missing id in draft");
        }
        return this.api.patch(draftApiPath(draft.id), removeUnEditableFields(draft))
            // @ts-ignore
            .then((result: AxiosResponse<DraftApiResponse>) => (result.data.data as Draft));
    }

    // POST /api/v2/public/cfp/drafts/100001000:submit
    async submitDraft(draft: Draft): Promise<Draft> {
        if (!draft.id) {
            throw new Error("missing id in draft");
        }
        return this.api.post(draftApiPathSubmit(draft.id))
            // @ts-ignore
            .then((result: AxiosResponse<DraftApiResponse>) => (result.data.data as Draft));
    }

    // POST /api/v2/public/cfp/drafts/100001000:cancel
    async cancelDraft(id: number): Promise<void> {
        if (!id) {
            throw new Error("missing id");
        }
        return this.api.post(draftApiPathCancel(id));
    }

    async getCfpEventByEventVersion(workspaceOrSlugId: string, evenProjectVersionSlug: EventProjectVersionSlug): Promise<CfpEvent | null> {
        const cfpList = await this.getOpenCfp(workspaceOrSlugId);
        const filteredCfp = cfpList.data.filter(cfp =>
            (
                cfp.projectKeyword?.toLowerCase() === evenProjectVersionSlug.projectKeyword.toLowerCase()
                && cfp.version?.toLowerCase().replaceAll("-"," ") === evenProjectVersionSlug.version.toLowerCase()
            )
            || cfp.eventSlug?.toLowerCase() === evenProjectVersionSlug.eventSlug.toLowerCase()
        ) || [];

        if (filteredCfp.length === 0) {
            return null;
        }

        return filteredCfp[0];
    }
}
